import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { socketUpdate } from './socketActions'

const DEBUG = process.env.NODE_ENV !== 'production';

const domain = window.location.hostname + (window.location.port ? ':' + window.location.port: '');
const wsUrl = DEBUG ? 'ws://10.0.0.162:1337/ws' : `wss://${domain}/ws`;

export let ws;

const SocketProvider = ({ children, socketUpdate }) => {

    useEffect(() => {
        if(!ws){
            ws = new WebSocket(wsUrl);

            ws.onopen = () => {
                console.log('Connected to server');
            }

            ws.onmessage = (event) => {
                const message = JSON.parse(event.data)

                // Deep merge with current state
                if(message){
                    socketUpdate(message);
                }
            }

            ws.onclose = () => {
                console.log('Disconnected from server');
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        children || null
    );
};

SocketProvider.propTypes = {
    children: PropTypes.node,
};

export default connect(null, {
    socketUpdate,
})(SocketProvider)

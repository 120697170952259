import makeStyles from '@material-ui/styles/makeStyles'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(3),
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: theme.palette.grey[900],
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.main,
        // color: theme.palette.text.primary,
        boxShadow: theme.shadows[5],
        fontSize: '4rem',
    },
    author: {
        fontSize: '0.8em',
        fontWeight: theme.typography.fontWeightMedium,
        fontStyle: 'italic',
    },
    text: {

    },
}));

const OverlayMessage = forwardRef(({ message }, ref) => {
    const classes = useStyles();

    if(!message) return null;

    return (
        <div className={classes.root} ref={ref}>
            <div className={classes.author}>
                {message.author.slice(0, 50)}
            </div>
            <div className={classes.text}>
                {message.text.slice(0, 180)}
            </div>
        </div>
    );
});

OverlayMessage.propTypes = {
    message: PropTypes.object,
};

export default OverlayMessage

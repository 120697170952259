import Paper from '@material-ui/core/Paper'
import React, { Component, Fragment } from 'react'
import { compose } from 'ramda'
import withStyles from '@material-ui/core/styles/withStyles'
import NotificationHandler from '../../modules/notification/NotificationHandler'
import withSocketInfo from '../../modules/socket/withSocketInfo'
import OverlayAnnounce from './OverlayAnnounce'
import OverlayEmoji from './OverlayEmoji'
import OverlayMessages from './OverlayMessages'

export const fixedFull = {
	position: 'fixed',
	top: 0,
	right: 0,
	left: 0,
	bottom: 0,
};

const styles = theme => ({
	root: {
		// backgroundColor: '#000000',
		backgroundColor: '#00FF00',
		// background: `url(${require('./borrel-back-3.png')})`,
		backgroundSize: 'cover',
		display: 'flex',
		...fixedFull,
	},
	infoPaper: {
	    position: 'fixed',
        right: 20,
        top: 20,
        padding: theme.spacing(2),
        display: 'inline-block',
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        fontSize: '2.7rem',
        '& strong': {
	        fontSize: '1.2em',
        },
    },
});

class OverlayPage extends Component {
    render() {
		const { classes, socket } = this.props;

		return(
			<Fragment>
				<div className={classes.root}>
					<OverlayMessages messages={socket.messages} />
					<OverlayEmoji emoji={socket.emoji} />
					<OverlayAnnounce announce={socket.announce} />
				</div>
                <Paper className={classes.infoPaper}>
                    Send a message via:<br/>
                    <strong>liveoverlay.nl</strong>
                </Paper>
				<NotificationHandler />
			</Fragment>
		);
	}

	componentDidMount(){
		document.body.style.overflowY = 'hidden';
	}

	componentWillUnmount(){
		document.body.style.overflowY = 'scroll';
	}
}

export default compose(
	withStyles(styles),
	withSocketInfo,
)(OverlayPage)

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useState } from 'react'
import { ws } from '../../modules/socket/SocketProvider'

const sendMessage = (author, text) => {
    ws.send(JSON.stringify({
        messages: [
            {
                author: author.slice(0, 50),
                text: text.slice(0, 180),
            },
        ],
    }));
};

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
    },
}));

const InputPage = () => {
    const classes = useStyles();

    const [ author, setAuthor ] = useState('');
    const [ text, setText] = useState('');

    const handleSend = () => {
        sendMessage(author, text);
        setText('');
    };

    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Send a message to everyone!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Author / from"
                                    value={author}
                                    onChange={(e) => setAuthor(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Message"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSend}
                                    fullWidth
                                >
                                    SEND!
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default InputPage

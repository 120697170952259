import React from 'react'
import { Route, Switch } from 'react-router-dom'
import InputPage from './app/input/InputPage'
import ManagePage from './app/manage/ManagePage'
import OverlayPage from './app/overlay/OverlayPage'

const App = () => {
    return (
        <div>
            <Switch>
                <Route path="/" exact component={InputPage} />
                <Route path="/overlay" component={OverlayPage} />
                <Route path="/manage-4722" component={ManagePage} />
            </Switch>
        </div>
    );
};

export default App

import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
    '@global': {

        // Main
        html: {
            fontSize: '62.5%',
        },
        body: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '1.4rem',
            overflowY: 'scroll',
            background: 'no-repeat center top fixed',
            backgroundColor: theme.palette.common.black,
            backgroundSize: 'cover',
        },

        // Text elements
        p: {
            ...theme.typography.body1,
        },

    },
});

const HtmlElementsStyled = () => null;

export default withStyles(styles)(HtmlElementsStyled)

import React from 'react'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import withStyles from '@material-ui/core/styles/withStyles'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import { notificationHide, notificationQueue } from './notificationActions'

const styles = theme => ({
	success: {
		backgroundColor: green[600],
	},
	warning: {
		backgroundColor: amber[700],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
	},
});

const NotificationHandler = ({ open, message, notificationHide, notificationQueue, classes }) => {
	return (
		<Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				  open={open}
				  autoHideDuration={3000}
				  onClose={(e, reason) => {
					  if(reason !== 'clickaway') notificationHide(reason)
				  }}
				  onExited={notificationQueue}>
			<SnackbarContent message={message && message.content}
							 className={message && classes[message.color]} />
		</Snackbar>
	);
}

export default withStyles(styles)(connect(state => {
	return {
		message: state.notification && state.notification.message,
		open: state.notification && state.notification.open,
	}
}, {
	notificationHide,
	notificationQueue,
})(NotificationHandler))

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles/makeStyles'
import React, { useState } from 'react'
import { ws } from '../../modules/socket/SocketProvider'

const sendEmoji = (emoji) => {
    ws.send(JSON.stringify({
        emoji: emoji,
    }));
};

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
    },
    buttons: {
        '& button+button': {
            marginLeft: theme.spacing(2),
        },
    },
}));

const ManagePage = () => {
    const classes = useStyles();

    const [ announce, setAnnounce ] = useState('');

    const sendAnnounce = () => {
        ws.send(JSON.stringify({
            announce: {
                show: Boolean(announce),
                text: announce,
            },
        }));

        // setAnnounce('');
    }

    const clearAnnounce = () => {
        ws.send(JSON.stringify({
            announce: {
                show: false,
            },
        }));
    }

    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1">
                                    Send emoji
                                </Typography>
                                <div className={classes.buttons}>
                                    <Button onClick={() => sendEmoji('🍑')} variant="outlined">
                                        <span role="img" aria-label="peach">🍑</span>
                                    </Button>
                                    <Button onClick={() => sendEmoji('🍍')} variant="outlined">
                                        <span role="img" aria-label="pineapple">🍍</span>
                                    </Button>
                                    <Button onClick={() => sendEmoji('🐬')} variant="outlined">
                                        <span role="img" aria-label="dolphin">🐬</span>
                                    </Button>
                                    <Button onClick={() => sendEmoji('🕺🏼')} variant="outlined">
                                        <span role="img" aria-label="dance-man">🕺🏼</span>
                                    </Button>
                                    <Button onClick={() => sendEmoji('💃🏼')} variant="outlined">
                                        <span role="img" aria-label="dance-lady">💃🏼</span>
                                    </Button>
                                    <Button onClick={() => sendEmoji(null)} variant="outlined">
                                        Clear
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="subtitle1">
                                    Send announcement
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Announce text"
                                            value={announce}
                                            onChange={(e) => setAnnounce(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="outlined" color="primary" onClick={sendAnnounce} fullWidth>
                                            Send
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="outlined" color="secondary" onClick={clearAnnounce} fullWidth>
                                            Clear
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ManagePage

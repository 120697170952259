import Zoom from '@material-ui/core/Zoom'
import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'
import PropTypes from 'prop-types'
import { fixedFull } from './OverlayPage'

const useStyles = makeStyles(theme => ({
    root: {
        ...fixedFull,
        zIndex: 1000,
    },
    emoji: {
        fontSize: 300,
        textAlign: 'center',
        marginTop: 200,
    },
}));

const OverlayEmoji = ({emoji}) => {
    const classes = useStyles();

    return (
        <Zoom in={Boolean(emoji)}>
            <div className={classes.root}>
                <div className={classes.emoji}>
                    {emoji}
                </div>
            </div>
        </Zoom>
    );
};

OverlayEmoji.propTypes = {
    emoji: PropTypes.string,
};

export default OverlayEmoji

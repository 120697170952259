import CssBaseline from '@material-ui/core/CssBaseline'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import HtmlElementsStyled from './components/utils/HtmlElementsStyled'
import SocketProvider from './modules/socket/SocketProvider'
import configureStore from './setup/store'
import theme from './theme'

const store = configureStore();
const finalTheme = createMuiTheme(theme);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={finalTheme}>
                <CssBaseline />
                <HtmlElementsStyled />
                <SocketProvider />
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

import { mapObjIndexed } from 'ramda'

// TODO: improve this, maybe simply deep merge - currently it replaces the full key
function entitiesReducerFinal(state = {}, action){
    if(action.response && action.response.entities){
        // Merge the whole state
        state = {
            ...state,
            ...mapObjIndexed((entities, key) => {
                // Merge each entity type with it possibly previous data
                return {
                    ...state[key],
                    ...mapObjIndexed((entity, id) => {
                        if(entity === null) return undefined;

                        // Merge each single entity with its possible previous entity
                        return {
                            ...state[key][id],
                            ...entity
                        };
                    }, entities)
                };
            }, action.response.entities)
        };
    }
    return state;
}

const entitiesReducer = {
    entities: entitiesReducerFinal,
};

export default entitiesReducer

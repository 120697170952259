import { mapObjIndexed } from 'ramda'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const defaultTheme = createMuiTheme();

// Correction for mobile view window vh unit
if(typeof document !== 'undefined' && typeof window !== 'undefined'){
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
}

const paletteColors = {
    primary: '#98c000',
    secondary: '#1c3a89',
    info: '#ef8536',
};

const theme = {
    typography: {
        htmlFontSize: 10,
        fontSize: 14,
        h1: {
            fontSize: '3rem',
            fontWeight: defaultTheme.typography.fontWeightMedium,
        },
        h2: {
            fontSize: '2.4rem',
        },
        h3: {
            fontSize: '1.8rem',
        },
        h4: {
            fontSize: '1.4rem',
        },
    },

    palette: {
        ...mapObjIndexed(
            color => defaultTheme.palette.augmentColor({ main: color, }),
            paletteColors
        ),
    },

    shape: {
        borderRadius: 2,
    },

    overrides: {

    },
};

export default theme

import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import { InfoOutlined, Mic } from '@material-ui/icons'
import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'
import PropTypes from 'prop-types'
import { fixedFull } from './OverlayPage'

const getIcon = (icon) => {
    if(!icon) return <Mic />

    switch(icon){
        case 'inf':
            return <InfoOutlined />
        default:
            return <Mic />
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        ...fixedFull,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '70%',
        fontSize: '6rem',
        padding: theme.spacing(4, 5),
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            fontSize: '1em',
            marginRight: theme.spacing(3),
        },
    },
    icon: {

    },
    text: {
        flex: 1,
    },
}));

const OverlayAnnounce = ({ announce }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Slide in={Boolean(announce && announce.show)}>
                <Paper className={classes.paper}>
                    {announce && (
                        <>
                            {getIcon(announce.icon)}
                            <div className={classes.text}>
                                {announce.text}
                            </div>
                        </>
                    )}
                </Paper>
            </Slide>
        </div>
    );
};

OverlayAnnounce.propTypes = {
    announce: PropTypes.object,
};

export default OverlayAnnounce

import { schema } from 'normalizr' // eslint-disable-line no-unused-vars

// Define all entities and basic relations
// const sector = newEntityWithUuid('sectors');

// Define dependencies
// event.define({
//     loan_uuid: loan,
// });


// Export used entities
export const Schemas = {
    // SECTOR: sector,
};

// Export initial schema, used by the store
export const initialSchemasState = {
    entities: {
        // sectors: {},
    },
};

// Utility to get the state key from a schema
export const getSchemaKey = (schema) => {
    if(!schema) return schema;

    if(Array.isArray(schema)){
        return schema[0].key;
    }

    return schema.key;
};

// Re-export api symbol for easier action creation
export { CALL_API } from './setup/api'

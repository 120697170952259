import { mergeDeepWith } from 'ramda'

const merger = (a, b) => {
    if(Array.isArray(a) && Array.isArray(b)){
        return a.concat(b);
    }

    return b;
}

function socketReducerFinal(state = {
	messages: [],
	inf: null,
}, action){
	switch(action.type){
		case 'SOCKET_UPDATE':
			return mergeDeepWith(merger, state, action.data);

        default:
            return state;
	}
}

const socketReducer = {
	socket: socketReducerFinal,
};

export default socketReducer

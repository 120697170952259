import { normalize } from 'normalizr'
import fetch from 'isomorphic-fetch'
import { mapObjIndexed } from 'ramda'
import { createApiTypes } from '../helpers/apiTypes'
// import { AUTH_LOGOUT } from '../modules/auth/authActions'

// TODO: clean up this whole mess

const API_ROOT = (process.env.REACT_APP_API_URL || '/api') + '/';

export function getApiUrl(path){
    return API_ROOT + path;
}

function callApi(endpoint, schema, method = 'GET', body = null, pagination, settings = {
    noConvertBody: false,
}) {
	const fullUrl = API_ROOT + endpoint;
	const options = {
		method: method && method.toUpperCase(),
		headers: {},
	};

	if(pagination){
	    console.error('Pagination not tailored to this application yet, make sure the API settings are correct as well as the data formats')
		const start = pagination.page * pagination.pageSize;

		options.headers['Range'] = start + '-' + (start + pagination.pageSize);
		options.headers['Range-Unit'] = 'items';
	}

	if(body){
	    if (options.method === 'MULTIPART') {
            const formData = new FormData();

            mapObjIndexed((val, key) => {
                formData.set(key, val);
            })(body)

            options.method = 'POST';
            options.body = formData;
            // options.headers['Content-Type'] = 'multipart/form-data';
        } else {
            if (options.method === undefined || options.method === 'GET') {
                options.method = 'POST';
            }

            options.body = settings.noConvertBody ? body : JSON.stringify(body);
            options.headers['Content-Type'] = 'application/json';//; charset=utf-8'; // TODO: ask why charset is somehow resulting in 400 error
        }
	}

	// TODO: add authentication information here (if defined by headers)
	return fetch(fullUrl, options)
		.then(response => {
			// Try to parse in json
			return response.json()
				.then(json => ({ json, response }))
				.catch(error => {
					return ({ json: null, response })
				});
		})
		.then(({ json, response }) => {
			// Response checks
			if (!response.ok) {
				return Promise.reject({
					...json,
					errorCode: response.status
				});
			}

			if(!json) return {};

			// Authentication
			// TODO: read authentication data if will be in the headers

			// Create final data
            let data = {
                result: json,
            };

            // Normalizing with scheme
            if(schema){
                data = {
                    ...normalize(json, schema),
                };
            }

			// Add pagination results
			if(pagination){
			    /*eslint no-unused-vars: ["off"] */
				const [ start, end, size ] = response.headers.get("content-range").split(/[-/]/).map(Number);

				// Change the page if it exceeded the total data
				// The final component can see this change and act on it
				data.pagination = {
					page: start > size
						? size <= pagination.pageSize ? 0 : Math.ceil(size / pagination.pageSize) - 1
						: Math.floor(start / pagination.pageSize),
					pageSize: pagination.pageSize,
					total: size,
				}
			}

			return data;
		})
		.catch((error) => {
			return Promise.reject(error);
		});
}

export const CALL_API = Symbol('Call API');

/**
 *  Merge extra API options in the existing API action object
 *
 * @param {object} action - action object
 * @param {object} options - extra options to merge, will override
 * @returns {object}
 */
export const apiMergeOptions = (action, options) => ({
    ...action,
    [CALL_API]: {
        ...action[CALL_API],
        ...options,
    },
});

/**
 * @callback generateEndpointCallback
 * @param {string} endpoint
 * @return {string} - Should return a string to replace the current endpoint
 */

/**
 * Update the API endpoint of an existing API action object
 *
 * @param {object} action - action object
 * @param {generateEndpointCallback} cb - callback function to generate the new endpoint
 * @returns {object}
 */
export const apiUpdateEndpoint = (action, cb) => ({
    ...action,
    [CALL_API]: {
        ...action[CALL_API],
        endpoint: cb(action[CALL_API].endpoint),
    },
});

/**
 * Add parameters to a given endpoint
 *
 * @param {string} endpoint - the endpoint
 * @param {string[]} params - list of params to add
 * @returns {object}
 */
export const apiAddParamsToEndpoint = (endpoint, params) => {
    return `${endpoint}${endpoint.indexOf('?') > -1 ? '&' : '?'}${params.join('&')}`;
};

/**
 *  Set the schema to an array in the existing API action object
 *
 * @param {object} action - action object
 * @returns {object}
 */
export const apiSetSchemaArray = (action) => ({
    ...action,
    [CALL_API]: {
        ...action[CALL_API],
        schema: action[CALL_API].schema && [action[CALL_API].schema],
    },
});

/**
 * Instruct the browser to download the given endpoint
 *
 * @param {string} endpoint
 * @param {string} filename
 */
export function apiDownload(endpoint, filename = undefined) {
    const fullUrl = API_ROOT + endpoint;

    let req = new XMLHttpRequest();
    req.open('GET', fullUrl);
    // req.setRequestHeader(auth_header_token, auth_token);
    req.responseType = 'blob';

    req.onload = function() {
        const blob = req.response;
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = filename ? filename : (req.getResponseHeader('content-disposition') || `attachment; filename=${filename}`).split("; ")[1].split("=")[1];
        link.click();
    };

    req.send();
}


export default store => next => action => {
	// Catch logout
	// if(action.type === AUTH_LOGOUT){
		// Do some logout
        // logoutAuthHeaders();
	// 	return next(action);
	// }

	// Get the API request data, otherwise forward
	const callAPI = action[CALL_API];
	if (typeof callAPI === 'undefined') {
		return next(action)
	}

	const { endpoint, schema, type, method, body, pagination, settings } = callAPI;

	function nextWith(data) {
		const finalAction = {...action, ...data};
		delete finalAction[CALL_API];
		return finalAction;
	}

	if(!type){
	    throw Error(`API call to endpoint "${endpoint}" should have a "type" defined in its action object`);
    }

	const [ requestType, successType, failureType ] = createApiTypes(type);
	next(nextWith({ type: requestType }));

	return callApi(endpoint, schema, method, body, pagination, settings).then(
		response => next(nextWith({
			response,
			type: successType
		})),
		error => {
			// Dispatch logout success if status is 401 to let the store know
			// if(error && error.errorCode === 401){
			// 	// logoutAuthHeaders();
			// 	next({
			// 		type: AUTH_LOGOUT,
			// 		autoLogout: true
			// 	});
			// }

			return next(nextWith({
				...error,
				type: failureType,
				message: error.message || error.msg || (
					error.errorCode === 500 ? 'Internal server error.' : 'Something went wrong...'
				),
				errorCode: error.errorCode,
			}))
		});
}

import Slide from '@material-ui/core/Slide'
import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import OverlayMessage from './OverlayMessage'
import { fixedFull } from './OverlayPage'

const useStyles = makeStyles(theme => ({
    root: {
        ...fixedFull,
        top: 'auto',
    },
}));

const OverlayMessages = ({ messages }) => {
    const classes = useStyles();
    const showingRef = useRef(false);
    const nextMessageRef = useRef(null);
    const messagesRef = useRef([]);
    const [ message, setMessage ] = useState(null);
    const [ showing, setShowing ] = useState(false);

    useEffect(() => {
        const next = nextMessageRef.current || 0;
        messagesRef.current = messages;

        // Message exists and nothing is showing
        if(messages[next] && !showingRef.current){
            setMessage(messages[next]);
            nextMessageRef.current = next + 1;
        }
    }, [messages, setMessage]);

    useEffect(() => {
        // Message changed, so need to show it
        if(message){
            showingRef.current = true;
            setShowing(true);

            setTimeout(() => {
                setShowing(false);

                // Wait a bit more for a new message
                setTimeout(() => {
                    const next = nextMessageRef.current || 0;

                    // If there is a next message, repeat everything
                    if(messagesRef.current[next]){
                        setMessage(messagesRef.current[next]);
                        nextMessageRef.current = next + 1;
                    }else{
                        // Else free the showing state
                        showingRef.current = false;
                    }
                }, 1000 * 1.2);
            }, 1000 * 30);
        }
    }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

    if(!messages) return null;

    return (
        <div className={classes.root}>
            <Slide in={showing} direction="up" mountOnEnter unmountOnExit timeout={500}>
                <OverlayMessage message={message} />
            </Slide>
        </div>
    );
};

OverlayMessages.propTypes = {
    messages: PropTypes.array,
};

export default OverlayMessages

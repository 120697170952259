import { combineReducers } from 'redux'
import socketReducer from '../modules/socket/socketReducer'
import entitiesReducer from './entitiesReducer'

let reducers = {
    ...entitiesReducer,
    ...socketReducer,
};

export default combineReducers(reducers)
